// Copyright 2023 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/**
 * searchParamUpdater update the URL parameter with the specified key and value.
 * If defaultValue is specified, it will delete the key when the value matches the default value.
 * If value is null, it will also delete the key.
 */
export function searchParamUpdater(
  key: string,
  value: string | null,
  defaultValue?: string,
) {
  return (params: URLSearchParams) => {
    const searchParams = new URLSearchParams(params);
    if ((defaultValue && defaultValue === value) || value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    return searchParams;
  };
}
